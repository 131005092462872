import React from 'react'

import Container from '../components/Container'
import Header from '../components/Header'
import Footer from '../components/Footer'
import H1 from '../components/H1'
import H2 from '../components/H2'
import H3 from '../components/H3'
import Hr from '../components/Hr'
import P from '../components/P'
import Layout from '../components/layout'

const Impressum = ({ location }) => (
  <Layout>
    <Header pathname={location.pathname} />
    <Container>
      <H1>Impressum</H1>
      <H2>Anbieterkennzeichnung nach §5 TMG</H2>
      <P>CSU Ortsverband Kastl<br />
      Johann Walter<br />
      Hauptstraße 19<br />
      95506 Kastl<br />
      <br />
      info@kastl-csu.de</P>

      <Hr />

      <H2>Disclaimer</H2>
      <H3>Urheberrechte</H3>
      <P>Copyright CSU Kastl. Alle Rechte vorbehalten. Text,  Bilder, Grafiken, Sound, Animationen und Videos sowie deren Anordnung  auf der CSU Kastl Website unterliegen dem Schutz des Urheberrechts und anderer  Schutzgesetze.<br />
      <br />
      Der Inhalt dieser Website darf nicht zu  kommerziellen Zwecken kopiert, verbreitet, verändert oder Dritten  zugänglich gemacht werden. Wir weisen daraufhin, dass auf den Websites  enthaltene Bilder teilweise dem Urheberrecht Dritter unterliegen.</P>
      <H3>Markenzeichen</H3>
      <P>Soweit  nicht anders angegeben, sind alle Markenzeichen auf dieser Website  markenrechtlich zugunsten der CSU Kastl geschützt. Dies gilt insbesondere für  Marken, Typenbezeichnungen, Logos und Embleme.</P>
      <H3>Haftung</H3>
      <P>Diese  Website wurde mit größtmöglicher Sorgfalt zusammengestellt. Trotzdem  kann keine Gewähr für die Fehlerfreiheit und Genauigkeit der enthaltenen  Informationen übernommen werden. Jegliche Haftung für Schäden, die  direkt oder indirekt aus der Benutzung dieser Website entstehen, wird  ausgeschlossen, soweit diese nicht auf Vorsatz oder grober  Fahrlässigkeit beruhen.<br />
      <br />
      Sofern von dieser Website auf  Internetseiten verwiesen wird, die von Dritten betrieben werden,  übernimmt die CSU Kastl keine Verantwortung für deren Inhalte.</P>
      <H3>Lizenz</H3>
      <P>Die  CSU Kastl möchte sich Ihnen mit einer innovativen und informativen Website  präsentieren. Das darin enthaltene geistige Eigentum wie Patente, Marken  und Urheberrechte ist geschützt. Durch diese Website wird keine Lizenz  zur Nutzung des geistigen Eigentums der CSU Kastl erteilt, soweit dies nicht  ausdrücklich so vorgesehen ist.</P>
    </Container>
    <Footer />
  </Layout>
)

export default Impressum